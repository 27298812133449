module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gtag@1.0.13_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_r_uici2nnye6armyeojfcdm2h56a/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-ZCQ9YSRPSY","head":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Pretendard"],"urls":["https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_esli_e4r4byge4jpfexfx5ssvwkv6vy/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_onbv2svzcco2r3hcvcsaes2hee/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SEOKKAMONI BLOG","short_name":"BLOG","start_url":"/","background_color":"#FFFFFF","icon":"/vercel/path0/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d9a33095a3556782b6ed58170e3ab38"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__ppul5sseigf5thrnusplpxc6tu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
